<template>
  <div class="container">
    <div class="container__control">
      <ClosePopUpButton
        class="black"
      />
    </div>

    <div class="container__icon">
      <svg
        v-if="_value.type !== 'error'"
        width="80" height="80"
        style="min-width: 80px; min-height: 80px;"
        viewBox="0 0 80 80" fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M20 0H40V20L20 40H0V20L20 0Z" fill="#2F57E9" fill-opacity="0.2"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M40 40H20L0 60V80H20L40 60V80H60L80 60V40H60L80 20V0H60L40 20V40ZM40 40H60L40 60V40Z" fill="#2F57E9" fill-opacity="0.2"/>
        <g clip-path="url(#clip0_1098_19858)">
          <path d="M60 24.5834C60 22.0551 57.9449 20 55.4166 20H24.5834C22.0551 20 20 22.0551 20 24.5834V55.4166C20 57.9449 22.0551 60 24.5834 60H55.4166C57.9449 60 60 57.9449 60 55.4166V24.5834Z" fill="#2F57E9"/>
          <path d="M50.1384 33.4049C50.79 34.0565 50.79 35.1096 50.1384 35.7615L39.305 46.5946C38.98 46.9196 38.5533 47.0832 38.1267 47.0832C37.7001 47.0832 37.2734 46.9196 36.9484 46.5946L31.5319 41.178C30.88 40.5265 30.88 39.473 31.5319 38.8215C32.1834 38.1696 33.2366 38.1696 33.8884 38.8215L38.1267 43.0598L47.7819 33.4049C48.4334 32.7531 49.4866 32.7531 50.1384 33.4049Z" fill="#D3FF53"/>
        </g>
        <defs>
          <clipPath id="clip0_1098_19858">
            <rect width="40" height="40" fill="white" transform="translate(20 20)"/>
          </clipPath>
        </defs>
      </svg>

      <svg
        v-else
        width="80" height="80"
        style="min-width: 80px; min-height: 80px;"
        viewBox="0 0 80 80" fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M20 0H40V20L20 40H0V20L20 0Z" fill="#DA2828" fill-opacity="0.2"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M40 40H20L0 60V80H20L40 60V80H60L80 60V40H60L80 20V0H60L40 20V40ZM40 40H60L40 60V40Z" fill="#DA2828" fill-opacity="0.2"/>
        <g clip-path="url(#clip0_2718_25946)">
          <path d="M60 24.5834C60 22.0551 57.9449 20 55.4166 20H24.5834C22.0551 20 20 22.0551 20 24.5834V55.4166C20 57.9449 22.0551 60 24.5834 60H55.4166C57.9449 60 60 57.9449 60 55.4166V24.5834Z" fill="#DA2828"/>
          <path d="M32 32L48 48M48 32L32 48" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
          <clipPath id="clip0_2718_25946">
            <rect width="40" height="40" fill="white" transform="translate(20 20)"/>
          </clipPath>
        </defs>
      </svg>
    </div>

    <p>
      {{ _value.text ? _value.text : $t('components.ui.pages.pop-up.successfully.completed') }}
    </p>
  </div>
</template>

<script>
import ClosePopUpButton from '@/ui/buttons/ClosePopUpButton.vue'

export default {
  components: {
    ClosePopUpButton
  },
  computed: {
    _value () {
      return this.$store.state.popUp.active
    }
  },
  mounted () {
    // setTimeout(() => {
    //   this.$pop.down()
    // }, 2000)
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  max-width: 355px;
  padding: 30px 20px;
  background: white;
  border-radius: 15px;
  overflow: hidden;

  .container__control {
    display: flex;
    justify-content: flex-end;
  }

  .container__icon {
    display: flex;
    justify-content: center;
  }

  p {
    margin-top: 30px;
    font-size: 18px;
    line-height: 100%;
    text-align: center;
    color: rgb(32, 37, 57);
    font-family: SemiBold;
  }
}
</style>
